<template>
  <Header
    :farmHeader="false"
    :title="t('page-user-profile.header')"
    icon="fa-user"
  />
  <Content>
    <div
      class="w-full h-full overflow-y-scroll md:grid md:grid-cols-5 md:grid-rows-5 gap-6 p-3 md:p-6"
    >
      <div class="md:col-span-3 md:row-span-2">
        <h2 class="w-full flex text-white text-xl mb-6 font-semibold">
          <span class="flex flex-shrink-0 justify-center items-center mr-4">
            <i class="fas fa-user"></i></span
          >{{ t("page-user-profile.personalInfo") }}
        </h2>

        <div class="text-white grid md:grid-cols-5 md:grid-rows-3 gap-6">
          <Input
            class="col-span-4"
            :label="t('page-user-profile.firstName')"
            labelWidth="w-4/12"
            inputWidth="w-8/12"
            v-model="firstName"
          />
          <Input
            class="col-span-4"
            :label="t('page-user-profile.lastName')"
            labelWidth="w-4/12"
            inputWidth="w-8/12"
            v-model="lastName"
          />
          <Input
            @keyup.enter="changeEmailSubmit()"
            class="col-span-4"
            icon="fa-at"
            labelWidth="w-4/12"
            inputWidth="w-8/12"
            v-model="email"
          />
          <Button
            v-if="emailChanged"
            class="col-span-1 bg-yellow"
            :label="t('page-user-profile.save')"
            @click="changeEmailSubmit()"
          />
        </div>
      </div>
      <div v-if="!isMobile()" class="col-span-2 row-span-2">
        <span
          class="mx-auto flex flex-shrink-0 justify-center items-center text-6xl bg-green rounded-full h-52 w-52"
        >
          <i class="fas fa-user"></i>
        </span>
      </div>
      <div class="md:col-span-3 md:row-span-3 mt-6 md:mt-0">
        <h2 class="w-full flex text-white text-xl mb-6 font-semibold">
          <span class="flex flex-shrink-0 justify-center items-center mr-4">
            <i class="fas fa-lock"></i> </span
          >{{ t("page-user-profile.security") }}
        </h2>
        <form
          @keyup.enter="changePasswordSubmit()"
          class="grid md:grid-cols-5 md:grid-rows-3 gap-6"
        >
          <Input
            type="password"
            class="col-span-4"
            icon="fa-key"
            labelWidth="w-4/12"
            inputWidth="w-8/12"
            :placeholder="t('page-user-profile.newCode1')"
            v-model="password1"
          />
          <Input
            type="password"
            class="col-span-4"
            icon="fa-key"
            labelWidth="w-4/12"
            inputWidth="w-8/12"
            :placeholder="t('page-user-profile.newCode2')"
            v-model="password2"
          />
          <Button
            v-if="password1"
            class="col-span-2 bg-yellow"
            :label="t('page-user-profile.save')"
            @click.prevent="changePasswordSubmit()"
          />
        </form>
      </div>
      <div v-if="password1" class="md:col-span-2 md:row-span-3">
        <p class=" text-yellow font-semibold flex mt-6 md:mt-12">
          <span class="flex flex-shrink-0 justify-center items-center mr-3">
            <i class="fas fa-exclamation"></i> </span
          >{{ t("page-user-profile.codeReqHeader") }}
        </p>
        <ul class="ml-12 text-white">
          <li class="flex">
            <span
              v-if="errorMinimumCharacters === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorMinimumCharacters"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            {{ t("page-user-profile.req1") }}
          </li>
          <li class="flex">
            <span
              v-if="errorContainsNumber === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsNumber"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            {{ t("page-user-profile.req2") }}
          </li>
          <li class="flex">
            <span
              v-if="errorContainsLowercase === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsLowercase"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            {{ t("page-user-profile.req3") }}
          </li>
          <li class="flex">
            <span
              v-if="errorContainsUppercase === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsUppercase"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            {{ t("page-user-profile.req4") }}
          </li>
          <li v-if="errorEqualPassword === false" class="flex">
            <span
              v-if="errorEqualPassword === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            {{ t("page-user-profile.req5") }}
          </li>
        </ul>
      </div>
    </div>
  </Content>
  <Modal
    @closeModal="showChangePasswordModal = !showChangePasswordModal"
    :visible="showChangePasswordModal"
  >
    <h1 class="text-yellow text-3xl mt-4 font-bold tracking-wider">
      {{ t("page-user-profile.confirmChange") }}
    </h1>
    <span
      class=" text-green border-2 border-green rounded-full w-14 h-14 flex justify-center items-center text-xl my-4"
    >
      <i class="fas fa-key"></i>
    </span>
    <p class="text-white text-center font-semibold mb-4">
      {{ t("page-user-profile.confirmChangeDescription") }}
    </p>
    <Input
      class="w-full md:w-3/4"
      icon="fa-lock"
      type="password"
      labelWidth="w-4/12"
      inputWidth="w-8/12"
      :placeholder="t('page-user-profile.currentCode')"
      v-model="password"
    />

    <Button
      :label="t('page-user-profile.confirm')"
      class="my-4 bg-yellow"
      @click="changePassword()"
    />
  </Modal>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Content from "@/components/Content";
import Header from "@/components/Header";
import { ref, watchEffect } from "vue";
import { errorLogging } from "@/global/services/errorLogging";
import { systemState } from "@/global/services/systemState";
import { authentication } from "@/global/services/authentication";
import {
  validateContainsLowercase,
  validateContainsNumber,
  validateContainsUppercase,
  validateMinimumCharacters
} from "@/global/services/mixins";
import { employee } from "./services/employee";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/global/services/mixins";

export default {
  name: "page-user-profile",
  components: {
    Modal,
    Input,
    Button,
    Content,
    Header
  },
  setup() {
    const userEmail = authentication.getUser().email;

    const { t } = useI18n();

    const state = {
      password: ref(""),
      password1: ref(""),
      password2: ref(""),
      errorContainsLowercase: ref(undefined),
      errorContainsNumber: ref(undefined),
      errorContainsUppercase: ref(undefined),
      errorMinimumCharacters: ref(undefined),
      errorEqualPassword: ref(undefined),
      errorVerification: ref(),
      showChangePasswordModal: ref(false),
      firstName: ref(""),
      lastName: ref(""),
      email: ref(userEmail),
      emailChanged: ref(false)
    };

    const functions = {
      isMobile,

      changeEmailSubmit: function() {
        if (location.hostname === "demo.farmhelp.dk") {
          errorLogging.setWarning(t("page-user-profile.noChangeDemo"));
        } else {
          systemState.startProcessing();
          const user = authentication.getUser();
          const employeeDoc = employee.get();

          user
            .updateEmail(state.email.value)
            .then(function() {
              systemState.stopProcessing();
              if (employeeDoc) {
                employee.set(employeeDoc, { email: state.email.value });
              }
              errorLogging.setSuccess(t("page-user-profile.emailUpdated"));
            })
            .catch(e => {
              systemState.stopProcessing();
              if (e.code === "auth/email-already-in-use") {
                errorLogging.setWarning(t("page-user-profile.emailInUse"));
              } else if (e.code === "auth/requires-recent-login") {
                systemState.dispatchReAuthentication();
                document.addEventListener("reAuthenticationComplete", event => {
                  if (event.detail) {
                    user
                      .updateEmail(state.email.value)
                      .then(function() {
                        errorLogging.setSuccess(
                          t("page-user-profile.emailUpdated")
                        );
                      })
                      .catch(e => {
                        errorLogging.setError(
                          e.message,
                          "page-user-profile.vue",
                          "page-user-profile",
                          "Line: 252"
                        );
                      });
                    document.removeEventListener(
                      "reAuthenticationComplete",
                      null
                    );
                  } else {
                    state.email.value = authentication.getUser().email;
                    document.removeEventListener(
                      "reAuthenticationComplete",
                      null
                    );
                  }
                });
              } else {
                errorLogging.setError(
                  e.message,
                  "page-user-profile.vue",
                  "page-user-profile",
                  "Line: 262"
                );
              }
            });
        }
      },

      changePasswordSubmit: function() {
        if (
          state.errorEqualPassword.value &&
          state.errorMinimumCharacters.value &&
          state.errorContainsUppercase.value &&
          state.errorContainsNumber.value &&
          state.errorContainsLowercase.value
        ) {
          state.showChangePasswordModal.value = true;
        }
      },

      changePassword: function() {
        if (location.hostname === "demo.farmhelp.dk") {
          errorLogging.setWarning(t("page-user-profile.noChangeDemoPassword"));
        } else {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              firebase.auth().currentUser.email,
              state.password.value
            )
            .then(() => {
              firebase
                .auth()
                .currentUser.updatePassword(state.password1.value)
                .then(() => {
                  state.errorEqualPassword.value = undefined;
                  state.errorMinimumCharacters.value = undefined;
                  state.errorContainsUppercase.value = undefined;
                  state.errorContainsNumber.value = undefined;
                  state.errorContainsLowercase.value = undefined;
                  state.password.value = "";
                  state.password1.value = "";
                  state.password2.value = "";
                  state.showChangePasswordModal.value = false;
                  errorLogging.setSuccess(
                    t("page-user-profile.passwordChanged")
                  );
                });
            })
            .catch(error => {
              if (error.code === "auth/wrong-password") {
                errorLogging.setWarning(t("page-user-profile.wrongPassword"));
              } else if (error.code === "auth/too-many-requests") {
                errorLogging.setWarning(t("page-user-profile.toManyRequests"));
                setTimeout(() => authentication.signOut(), 10000);
              } else {
                console.log(error.code);
                errorLogging.setError(
                  error.message,
                  "page-user-profile.vue",
                  "page-user-profile",
                  "Line: 365"
                );
              }
            });
        }
      }
    };

    watchEffect(() => {
      state.emailChanged.value =
        state.email.value !== authentication.getUser().email;
    });

    watchEffect(() => {
      if (authentication.getUserProfile() !== null) {
        state.firstName.value = authentication.getUserProfile().firstName;
        state.lastName.value = authentication.getUserProfile().lastName;
      }
    });
    watchEffect(() => {
      if (state.password1.value !== "") {
        state.errorContainsLowercase.value = validateContainsLowercase(
          state.password1.value
        );
        state.errorContainsNumber.value = validateContainsNumber(
          state.password1.value
        );
        state.errorContainsUppercase.value = validateContainsUppercase(
          state.password1.value
        );
        state.errorMinimumCharacters.value = validateMinimumCharacters(
          state.password1.value
        );
      }
    });
    watchEffect(() => {
      if (state.password2.value !== "") {
        if (state.password2.value !== state.password1.value) {
          state.errorEqualPassword.value = false;
        } else {
          state.errorEqualPassword.value = true;
        }
      }
    });

    return {
      t,
      ...state,
      ...functions
    };
  }
};
</script>
